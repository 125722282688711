import React, { Component } from "react"
import Typography from "@material-ui/core/Typography"
import Layout from "../components/layout"
import Button from "@material-ui/core/Button"
import SEO from "../components/seo"

import Pdf from "../documents/ADC_Application.pdf"
const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
  },
})

export class laborer extends Component {
  render() {
    const { classes } = this.props
    return (
      <Layout>
        <SEO
          title="Home"
          keywords={[`adc`, `laborer`, `career`, `job`, `alaska`]}
        />
        <div className="container-body">
          <Typography variant="h3" gutterBottom>
            {" "}
            Laborer
          </Typography>
          <Typography variant="h4" gutterBottom>
            {" "}
            Job Summary
          </Typography>
          <p>
            {" "}
            We are looking for an efficient, safety minded laborer for
            residential and commercial construction to join our team. The ideal
            candidate will be capable of working with minimal oversight but
            willing to follow directions closely.
          </p>
          <ul>
            <li>
              Climb heights and conduct work with proper safety restraints
            </li>
            <li>Perform setup and cleanup tasks at job site</li>
            <li>Digging in preparation for jobsite construction</li>
            <li> Operate equipment with direction </li>
            <li>Deliver equipment, supplies, materials, etc. as directed </li>
            <li> Provide labor support as needed </li>
            <li> This list in not all inclusive (Other duties as required)</li>
          </ul>

          <h3> Requirements</h3>
          <ul>
            <li>
              {" "}
              Must be able to work up to seven days a week, twelve hours a day
              while on the job.{" "}
            </li>
            <li>
              {" "}
              Self-motivated, able to follow direction and must have good
              communication skills
            </li>
            <li>
              {" "}
              Personal commitment to team environment, safety, client
              satisfaction, and quality control{" "}
            </li>
            <li>
              {" "}
              Must be safety conscious and adhere to company and OSHA
              requirements{" "}
            </li>
            <li> Must be able to lift 80 pounds </li>
            <li> Works well with others and can work independently</li>
          </ul>

          <p>
            {" "}
            We encourage you to apply if interested in this position! Please
            download our application form and once completed submit your signed
            application, either{" "}
            <a href=" mailto:info@adcalaska.com">
              {" "}
              through email at info@adcalaska.com{" "}
            </a>{" "}
            or fax it to (907) 357-8630{" "}
          </p>
          <div className="buttonContainer">
            <Button
              size="large"
              variant="contained"
              color="secondary"
              href={Pdf}
              download
            >
              Fillable PDF Application
            </Button>
          </div>
          <br />
          <div className="buttonContainer">
            <Button
              size="medium"
              variant="outlined"
              color="primary"
              href="mailto:info@adcalaska.com"
            >
              Attach and Submit{" "}
            </Button>
          </div>
        </div>
      </Layout>
    )
  }
}

export default laborer
